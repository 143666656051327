<template>
    <div>
        <div class="renderer">
            <slot name="Start" />
        </div>
        <div class="renderer" v-if="showEventResults">
            <div>
                <slot name="eventTotalNoteLabel">{{ eventNoteLabel }}</slot>
            </div>
            <div style="display: flex; align-content: center; align-items:center;">
            <div class="global" style="margin-right:20px;">{{ eventNote }}</div>
            <b-badge pill size="2.8em" variant="warning" class="rank">{{ (eventRank > 0) ? eventRank : 'N/A' }}<sup>{{ getRankSup(eventRank) }}</sup></b-badge>
            </div>
        </div>
<!--        <div class="renderer" v-if="showEventResults">
            <slot name="betweenEventNoteAndRank" />
        </div>
        <div class="renderer" v-if="showEventResults && showEventRank">
            <div>
                <slot name="eventRankLabel">{{ eventRankLabel }}</slot>
            </div>
            <b-badge pill variant="warning" class="rank">{{ eventRank }}</b-badge>
        </div>-->
        <div class="renderer" v-if="showEventResults">
            <slot name="betweenEventAndOverallInfos" />
        </div>
        <hr v-if="showEventResults && showOverallResults" />
        <div class="renderer" v-if="showOverallResults && (overallNote !== null)">
            <div>
                <slot name="overallNoteLabel">{{ overallNoteLabel }}</slot>
            </div>
            <div class="global">{{ overallNote }}</div>
        </div>
        <div class="renderer" v-if="showOverallResults && (overallNote != null)">
            <div>
                <slot name="overallRankLabel">{{ overallRankLabel }}</slot>
            </div>
            <b-badge pill variant="warning" class="rank">{{ (overallRank > 0) ? overallRank : 'N/A' }}<sup>{{ getRankSup(overallRank) }}</sup></b-badge>
        </div>
        <div class="renderer">
            <slot name="End">
                <b-button class="nextStep" variant="primary" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
    </div>
</template>

<script>

import { CompetitorsNotesHelper } from "@/services/helpers/competitorsNotesHelper";

export default {
    props: {
    },
    data(){
        return {
            eventNoteLabel: 'Note finale et classement provisoire de l\'épreuve',
            eventNote: '',
            eventRankLabel: 'Classement (provisoire) pour l\'épreuve',
            eventRank: '',
            overallNoteLabel: 'Note globale et classement provisoire de la manche',
            overallNote: '',
            overallRankLabel: 'Classement général provisoire',
            overallRank: '',
            showEventRank: true,
            showEventResults: true,
            showOverallResults: true,
        };
    },
    computed:{
        //...mapGetters('currentState', ['currentCategory', 'currentCompetitor', 'currentEvent'] ),
    },
    methods:{
        reset(){
            this.eventGlobaleNote = '';
            this.eventRank = '';
            this.overallNote = '';
            this.overallRank = '';
            this.showEventResults = true;
            this.showEventRank = true;
            this.showOverallResults = true;
            this.eventNoteLabel = 'Note finale de l\'épreuve & classement provisoire pour l\'épreuve';
            this.eventRankLabel = 'Classement (provisoire) pour l\'épreuve';
            this.overallNoteLabel = 'Note globale provisoire pour la manche';
            this.overallRankLabel = 'Classement général provisoire pour la manche';
        },
        refresh(){
            this.reset();

            var competitorNotes = CompetitorsNotesHelper
                                    .getNotes(this.currentEvent.competition_id,
                                              this.currentEvent.round_number,
                                              this.currentEvent.level,
                                              this.currentEvent.category,
                                              this.currentCompetitor.competitor_id);
            if(competitorNotes == null)
            {
                return;
            }

            this.overallNote = this.arrondir(competitorNotes.overall);
            this.overallRank = competitorNotes.overall_rank;
            this.showOverallResults = competitorNotes.isCompleted;

            if(this.currentEvent.EVENT.hasCompulsories){
                if(this.currentEvent.EVENT.hasBallet && this.currentEvent.EVENT.hasRoutine)
                {
                    this.eventNote = this.arrondir(competitorNotes.overall);
                    this.eventRank = competitorNotes.overall_rank;
                } else if(this.currentEvent.EVENT.hasBallet)
                {
                    if(this.currentCategory.events.length == 1)
                    {
                        this.eventNote = this.arrondir(competitorNotes.total_overall);
                        this.eventRank = competitorNotes.overall_rank;
                        this.showEventResults = false;
                        this.showOverallResults = true;
                    }else{
                        this.eventNote = this.arrondir((competitorNotes.compulsories_total * this.currentCategory.figuresPound)
                                          + (competitorNotes.ballet_total * this.currentCategory.balletPound));
                        this.eventRank = 'N/A';
                    }
                }
                else if(this.currentEvent.EVENT.hasRoutine)
                {
                    this.eventNote = this.arrondir(competitorNotes.precision_total);
                    this.eventRank = competitorNotes.precision_total_rank;
                }
                else
                {
                    this.eventNote = this.arrondir(competitorNotes.compulsories_total);
                    this.eventRank = competitorNotes.compulsories_total_rank;
                }
            } else if (this.currentEvent.EVENT.hasBallet)
            {
                if(this.currentEvent.EVENT.hasRoutine){
                    this.eventNote = this.arrondir((competitorNotes.routine_total * this.currentEvent.RULE.poundOfRoutineWithinPrecision)
                                          + (competitorNotes.ballet_total * this.currentCategory.balletPound));
                    this.eventRank = 'N/A';
                } else {
                    this.eventNote = this.arrondir(competitorNotes.ballet_total);
                    this.eventRank = this.arrondir(competitorNotes.ballet_total_rank);
                }
            }
            else if(this.currentEvent.EVENT.hasRoutine)
            {
                this.eventNote = this.arrondir(competitorNotes.routine_total);
                this.eventRank = competitorNotes.routine_total_rank;
            }
        },
        getRankSup(rank){
            if(rank === null || rank === '' || rank === "N/A" || rank === '0' || rank === 0){
                return '';
            }

            if(rank === 1 || rank ==='1') {
                return 'er';
            } else {
                return 'ème';
            }
        },
        /*
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },*/
        nextStep(){
            this.$emit("nextStep");
        },
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .global.details{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .global.execution, .global.choreo{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 45px;
        font-size:0.25em;
        border:none;
        font-weight: lighter;
    }
    .global.execution > .note, .global.choreo > .note{
        font-size:2.5em;
        font-weight: lighter;
    }
    .global.choreo {
        border-top:solid 1px black;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        font-size:0.8em; border: solid 1px darkgray; color:darkgray; width:80px; height:80px; margin: 10px 10px;
        padding: 2px 0px;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .judgeNote > .execution, .judgeNote > .choreo
    {
        font-size:0.9em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2.5em;
        /*border:solid 1px darkgreen; background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .rank{
        font-size:2em;
    }

</style>
